import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Services = (props) => {
    return (
        <section id="services" className={props.className}>
			
			{/* <!-- Container --> */}
			<Container>
				
				{/* <!-- Section title --> */}
				<Row className="justify-content-center">
					<Col className="col-12 col-md-10 col-lg-6">
						
						<div className="section-title text-center">
							<h3>What we do</h3>
							<p>It all starts with your data, and how that data flows through your organisation.<br></br>
							 We look at all aspects of your data, systems, people & process - and from there we work out how to get better value from it.</p>
						</div>
						
					</Col>
				</Row>
			
				<Row>
					
					{/* <!-- Service 1 --> */}
					<Col className="col-12 col-lg-4 res-margin wow fadeInUp" data-wow-offset="10" data-wow-duration="1s" data-wow-delay="0">
						<div className={props.serviceClassName}>
							
							<div className="icon icon-basic-server2"></div>
							
							<h5>Data & Information</h5>
							<p>We start by understanding the data you need to run your business and how that flows through your organisation.
								We look at your chart of accounts, how you code your customers, how you assess deal flow etc. We pretty much get into all aspects of
								how data defines and flows in your organisation.
							</p>
							
						</div>
					</Col>

					{/* <!-- Service 2 --> */}
					<Col className="col-12 col-lg-4 res-margin wow fadeInUp" data-wow-offset="10" data-wow-duration="1s" data-wow-delay="0.3s">
						<div className={props.serviceClassName}>
							
							<div className="icon icon-basic-cloud"></div>
							
							<h5>Platforms & Systems</h5>
							<p>What platforms & systems do you run and how do they integrate? We look at your financial systems, 
								we look at your sales systems, we review your website, the contact forms that are used, how POs get rasied, 
								we get under the covers of everything you use and how they come together.
								</p>

						
						</div>
					</Col>

					{/* <!-- Service 3 --> */}
					<Col className="col-12 col-lg-4 res-margin wow fadeInUp" data-wow-offset="10" data-wow-duration="1s" data-wow-delay="0.6s">
						<div className={props.serviceClassName}>
							
							<div className="icon icon-basic-share"></div>
							
							<h5>Process & People</h5>
							<p>Data is not static - it flows through an organisation - driven by people doing work and systems automating activities. 
								We get inside how this currently works for you and we then consider what can be made better. We look to automate the easier stuff,
								and get people involved in the hard stuff.
								</p>
						
						</div>
					</Col>
					
				</Row>
				<Row>
					
					{/* <!-- Service 1 --> */}
					<Col className="col-12 col-lg-4 res-margin wow fadeInUp" data-wow-offset="10" data-wow-duration="1s" data-wow-delay="0">
						<div className={props.serviceClassName}>
							
							<div className="icon icon-basic-compass"></div>
							
							<h5>Alignment to Strategy</h5>
							<p>It is impossible to redefine data structures to become data driven if you do not understand the overall 
								commercial strategy of an organisation. It can also become a virtuous circle when being data driven can help shape strategy.
								We help our customers navigate this important alignment.
								</p>
							
						</div>
					</Col>

					{/* <!-- Service 2 --> */}
					<Col className="col-12 col-lg-4 res-margin wow fadeInUp" data-wow-offset="10" data-wow-duration="1s" data-wow-delay="0.3s">
						<div className={props.serviceClassName}>
							
							<div className="icon icon-basic-sheet-txt"></div>
							
							<h5>Reporting & Information</h5>
							<p>Getting hold of your data and then turning it into actionable information is critical to becoming data driven.
								You can have the cleverest most complex integrations and automations in the world but if it does not deliver 
								actionable information whats the point? We ensure we make this happen.
								
							</p>
						
						</div>
					</Col>

					{/* <!-- Service 3 --> */}
					<Col className="col-12 col-lg-4 res-margin wow fadeInUp" data-wow-offset="10" data-wow-duration="1s" data-wow-delay="0.6s">
						<div className={props.serviceClassName}>
							
							<div className="icon icon-basic-case"></div>
							
							<h5>Plan, Build & Deployment</h5>
							<p>So we have done the analysis, we have reviewed your systems, we have understood your proccess and people, 
								we have considered your commercial startegy and we get your data. We now plan, build and deploy the agreed improvements - whatever they may be. Simple really!</p>
						
						</div>
					</Col>
					
				</Row>				
			</Container>
			
		</section>
    );
}

export default Services;