import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

// Images
import LogoWhite from "~images/logo-white.png";

export default class Footer extends Component {

	componentDidMount() {
		// To top
		const toTop = document.querySelector('.to-top');

		// Scroll
		window.addEventListener('scroll', function() {
			const mainMenu = document.querySelector('.main-menu-area');
			const pos = window.scrollY;
	
			if (mainMenu) {
				if (pos >= 100) {
					mainMenu.classList.add('fixed-menu', 'animate', 'slideInDown');
				} else {
					mainMenu.classList.remove('fixed-menu', 'animate', 'slideInDown');
				}
			}
	
			// Scroll to top button
			if (pos >= 500) {
				toTop.classList.add('fixed-totop');
			} else {
				toTop.classList.remove('fixed-totop');
			}
		});
	}

	render() {
		return (
			<footer>
				
				{/* <!-- Widgets --> */}
				<div className="footer-widgets">
					<Container>
						
						<Row>
							
							{/* <!-- Footer logo --> */}
							<Col className="col-12 col-md-6 col-lg-3 res-margin">
								<div className="widget">
									<p className="footer-logo">
										<img src={LogoWhite} srcSet={`${LogoWhite} 2x`} alt="Abacus black" />
									</p>
									<p>
										We are accountants, bookkeepers, coders & data wranglers who love getting our customers Data Driven.
									</p>
									
									{/* <!-- Social links --> */}
								</div>
							</Col>
							
							{/* <!-- Useful links --> */}
							<Col className="col-12 col-md-6 col-lg-2 offset-lg-1 res-margin">
								<div className="widget">
									
									<h6><br></br><br></br>Useful Links</h6>
									
									<ul className="footer-menu">
										<li><a href="/#services">What we do</a></li>
										<li><a href="/#contact">Get in Touch</a></li>
									</ul>
									
								</div>
							</Col>
							
							{/* <!-- Product help --> */}
							
							{/* <!-- Download --> */}
							
						</Row>
						
					</Container>
				</div>
				
				{/* <!-- Copyright --> */}
				<div className="footer-copyright">				
					<Container>
						
						<Row>						
							<Col className="col-12">
								
								{/* <!-- Text --> */}
								<p className="copyright text-center">
									Copyright © 2022 <a href="/" target="_blank">Abacus black</a>. All Rights Reserved.
								</p>
								
							</Col>
						</Row>
						
					</Container>				
				</div>

			</footer>
		);
	}
	
}